import { NavMenu } from "../../common/navMenu.js";
import { globalHeaderScrollTop } from "../../common/globalHeaderScrollTop.js";

// props: {
//   animationDelay: number
//   closed: boolean
//   direction: string (horizontal or vertical)
//   menuButton: string (class or id selector)
//   menuContainer: string (class or id selector)
//   menuLink: string (class selector)
//   onOpen: function (callback when the menu opens)
//   parent: node element
// }

export default function initDesktopHeader() {
  const desktopHeaderEls = document.querySelectorAll(".js-desktop-header-item");
  const searchMenuEl = document.querySelector(".js-desktop-header-search");

  if (desktopHeaderEls) {
    desktopHeaderEls.forEach((el, i) => {
      const desktopMenu = new NavMenu({
        animationDelay: 600,
        closed: true,
        direction: "vertical",
        menuButton: ".js-desktop-header-open",
        menuContainer: ".js-desktop-header-menu-container",
        menuLink: ".js-desktop-header-link",
        parent: el,
        hover: true,
      });
    });
  }

  if (searchMenuEl) {
    const searchMenu = new NavMenu({
      animationDelay: 600,
      closed: true,
      direction: "vertical",
      menuButton: ".js-desktop-header-search-open",
      closeButton: ".js-desktop-header-search-close",
      menuContainer: ".js-desktop-header-search-menu-container",
      menuLink: ".js-desktop-header-search-input",
      parent: searchMenuEl,
    });
  }

  const globalHeader = document.getElementsByClassName("global-header")[0]
    ? document.getElementsByClassName("global-header")[0]
    : null;

  globalHeaderScrollTop(globalHeader);
}
