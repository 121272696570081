// disabling by client request
//import { disablePageScroll, enablePageScroll } from "scroll-lock";

// props: {
//   animationDelay: number
//   closed: boolean
//   direction: string (horizontal or vertical)
//   menuButton: string (class or id selector)
//   menuContainer: string (class or id selector)
//   menuLink: string (class selector)
//   onOpen: function (callback when the menu opens)
//   parent: node element
//   hover: bool, should open on hover
// }

let throttle = window.setTimeout(() => {}, 0);

export class NavMenu {
  constructor(props) {
    const defaultProps = {
      closed: true,
      direction: "vertical",
      animationDelay: 600,
      hover: false,
    };

    this.props = Object.assign({}, defaultProps, props);

    this.closed = this.props.closed;
    this.direction = this.props.direction;
    this.animationDelay = this.props.animationDelay;
    this.hover = this.props.hover;

    this.elParent = this.props.parent;

    if (this.props.closeSearchButton) {
      this.elCloseSearchButton = this.props.closeSearchButton;
    }

    this.elMenuButton = this.elParent.querySelector(this.props.menuButton);

    if (this.props.closeButton) {
      this.elCloseButton = this.elParent.querySelector(this.props.closeButton);
    }

    this.elMenuContainer = this.elParent.querySelector(
      this.props.menuContainer,
    );
    this.elMenuLinks = this.elParent.querySelectorAll(this.props.menuLink);

    this.onOpen = this.props.onOpen;
    this.openClass = "is-open";

    this.bindEvents();

    if (this.elMenuButton) {
      if (this.closed) {
        this.closeMenu(false);
      } else {
        this.openMenu();
      }
    }
  }

  bindEvents() {
    if (this.elCloseButton) {
      this.elCloseButton.addEventListener("click", (e) => {
        e.preventDefault();
        this.closeMenu();
      });
    }

    if (this.elMenuButton) {
      // Only allow mousedown if it's not the desktop primary nav
      this.elMenuButton.addEventListener("mousedown", (e) => {
        if (
          this.elMenuButton.className.indexOf("js-desktop-header-open") === -1
        ) {
          e.preventDefault();
          this.toggleMenu();
        }
      });

      if (this.hover) {
        //add mousenter and leave to parent element so menu doesnt close when mouse exits button
        this.elMenuButton.parentElement.addEventListener("mouseenter", (e) => {
          e.preventDefault();
          this.openMenu();
        });

        this.elMenuButton.parentElement.addEventListener("mouseleave", (e) => {
          e.preventDefault();
          this.closeMenu();
        });
      }

      this.elMenuButton.addEventListener("keydown", (e) => {
        switch (e.key) {
          case "Escape":
            e.preventDefault();
            this.closeMenu();
            break;
          case "Enter":
          case " ":
            e.preventDefault();
            this.toggleMenu();
            break;
          default:
        }
      });

      document.querySelector("html").addEventListener("click", (e) => {
        if (!this.elParent.contains(e.target) && !this.closed) {
          this.closeMenu(false);
        }
      });

      [...this.elMenuLinks].forEach((link, index) => {
        link.addEventListener("keydown", (e) => {
          let target = index;

          switch (e.key) {
            case "Escape":
              e.preventDefault();
              this.elMenuButton.focus();
              this.closeMenu();
              break;
            case "Tab":
              e.preventDefault();
              if (e.shiftKey) {
                if (index === 0) {
                  this.closeMenu();
                  this.elMenuButton.focus();
                } else {
                  this.elMenuLinks[target - 1].focus();
                }
              } else {
                if (index === this.elMenuLinks.length - 1) {
                  this.closeMenu();
                  this.elMenuButton.focus();
                } else {
                  this.elMenuLinks[target + 1].focus();
                }
              }
              break;
            default:
          }

          if (this.direction === "vertical") {
            switch (e.key) {
              case "Up":
              case "ArrowUp":
                e.preventDefault();
                if (index === 0) {
                  target = this.elMenuLinks.length;
                }
                this.elMenuLinks[target - 1].focus();
                break;
              case "Down":
              case "ArrowDown":
                e.preventDefault();
                if (index === this.elMenuLinks.length - 1) {
                  target = -1;
                }
                this.elMenuLinks[target + 1].focus();
                break;
              default:
            }
          } else {
            switch (e.key) {
              case "Left":
              case "ArrowLeft":
                e.preventDefault();
                if (index === 0) {
                  target = this.elMenuLinks.length;
                }
                this.elMenuLinks[target - 1].focus();
                break;
              case "Right":
              case "ArrowRight":
                e.preventDefault();
                if (index === this.elMenuLinks.length - 1) {
                  target = -1;
                }
                this.elMenuLinks[target + 1].focus();
                break;
              default:
            }
          }
        });
      });
    }
  }

  setDirection(direction) {
    this.direction = direction;
  }

  toggleMenu() {
    if (this.closed) {
      this.openMenu();
    } else {
      this.closeMenu();
    }
  }

  openMenu() {
    this.closed = false;
    this.elMenuButton.setAttribute("aria-expanded", true);
    this.elParent.classList.add(this.openClass);
    this.elMenuContainer.removeAttribute("aria-hidden");

    // disabling by client request
    // disablePageScroll();
    // window.clearTimeout(throttle);
    //
    // throttle = setTimeout(() => {
    //   this.elMenuLinks[0].focus();
    // }, this.animationDelay);

    if (this.elCloseSearchButton !== undefined) {
      this.elCloseSearchButton.classList.add(this.openClass);
    }

    if (this.onOpen) {
      this.onOpen();
    }
  }

  closeMenu(moveFocus = true) {
    this.closed = true;
    // disabling by client request
    // if (moveFocus) {
    //   this.elMenuButton.focus();
    // }
    this.elMenuButton.setAttribute("aria-expanded", false);
    this.elParent.classList.remove(this.openClass);
    this.elMenuContainer.setAttribute("aria-hidden", true);

    if (this.elCloseSearchButton !== undefined) {
      this.elCloseSearchButton.classList.remove(this.openClass);
    }

    // disabling by client request
    // enablePageScroll();

    //clear any inputs within the nav on close
    const inputEls = this.elMenuContainer.querySelectorAll("input");
    if (inputEls.length > 0) {
      inputEls.forEach((el) => (el.value = ""));
    }
  }
}
