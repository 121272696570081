import QuickSearchBar from "./QuickSearchBar.jsx";
import ReactDom from "react-dom";

export default function renderQuickSearchBar() {
  document
    .querySelectorAll(".js-quick-search-bar-mount")
    .forEach((mount, i) => {
      const dataModel = JSON.parse(mount.dataset.model);

      ReactDom.render(<QuickSearchBar index={i} {...dataModel} />, mount);
    });
}
