import { NavMenu } from "../../common/navMenu.js";

// props: {
//   animationDelay: number
//   closed: boolean
//   direction: string (horizontal or vertical)
//   menuButton: string (class or id selector)
//   menuContainer: string (class or id selector)
//   menuLink: string (class selector)
//   onOpen: function (callback when the menu opens)
//   parent: node element
// }

export default function initMobileHeader() {
  const mobileHeaderWrapperEl = document.querySelector(".mobile-header");
  const mobileHeaderEl = document.querySelector(".js-mobile-header");
  const searchMenuEl = document.querySelector(".js-mobile-header-search");
  const subMenuEls = document.querySelectorAll(".js-mobile-header-submenu");

  if (mobileHeaderEl && mobileHeaderWrapperEl) {
    const mobileHeader = new NavMenu({
      animationDelay: 600,
      closed: true,
      direction: "vertical",
      menuButton: ".js-mobile-header-open",
      closeButton: ".js-mobile-header-close",
      menuContainer: ".js-mobile-header-menu-container",
      menuLink: ".js-mobile-header-link",
      parent: mobileHeaderEl,
    });
  }

  if (searchMenuEl) {
    const searchMenu = new NavMenu({
      animationDelay: 600,
      closed: true,
      direction: "vertical",
      menuButton: ".js-mobile-header-search-open",
      closeButton: ".js-mobile-header-search-close",
      menuContainer: ".js-mobile-header-search-menu-container",
      menuLink: ".js-mobile-header-search-input",
      parent: searchMenuEl,
    });
  }

  if (subMenuEls) {
    subMenuEls.forEach((el, i) => {
      const searchSubMenu = new NavMenu({
        animationDelay: 600,
        closed: true,
        direction: "vertical",
        menuButton: ".js-mobile-header-submenu-open",
        closeButton: ".js-mobile-header-submenu-close",
        menuContainer: ".js-mobile-header-submenu-container",
        menuLink: ".js-mobile-header-submenu-link",
        parent: el,
      });
    });
  }
}
