import { arrayOf, shape, string } from "prop-types";
import { useState } from "react";
import cx from "classnames";

const propTypes = {
  placeholderText: string.isRequired,
  quickLinks: arrayOf(
    shape({
      href: string.isRequired,
      text: string.isRequired,
      target: string.isRequired,
    }).isRequired,
  ),
  facultySearchUrl: string.isRequired,
  researchSearchUrl: string.isRequired,
  websiteSearchUrl: string.isRequired,
};

const QuickSearchBar = (props) => {
  const {
    placeholderText,
    quickLinks,
    facultySearchUrl,
    researchSearchUrl,
    websiteSearchUrl,
  } = props;

  const [query, setQuery] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    //default to main website search on submit
    window.location.assign(`${websiteSearchUrl + query}`);
  };

  // Using document.querySelector here b/c desktop header close search button is part of
  // DesktopHeader.jsx, which isn't actually rendered as a React component.
  // Need to access its click event like this
  document
    .querySelector(".js-desktop-header-search-close")
    .addEventListener("click", () => {
      setQuery("");
    });

  return (
    <div className="quick-search-bar">
      <form
        onSubmit={(e) => handleSubmit(e)}
        className="quick-search-bar__form"
      >
        <div className="quick-search-bar__search-input">
          <input
            onChange={(e) => {
              e.preventDefault();
              setQuery(e.target.value);
            }}
            // placeholder={placeholderText}
            type="text"
            value={query}
            aria-label="Enter search text"
          />

          <button
            className={cx("quick-search-bar__clear-btn", {
              "--is-active": query,
            })}
            type="button"
            aria-label="Clear search field"
            onClick={(e) => {
              setQuery("");
            }}
          >
            {closeIcon}
          </button>

          <button
            className="quick-search-bar__search-btn"
            type="submit"
            aria-label="Submit search query"
          >
            {searchIcon}
          </button>
        </div>
      </form>

      {!query && (
        <div className="quick-search-bar__quick-links">
          <h2>Quick Links</h2>
          <ul>
            {quickLinks.map((link, i) => (
              <li key={i}>
                <a href={link.href} target={link.target}>
                  {link.text}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}

      {query && (
        <div className="quick-search-bar__find">
          <div>
            <a href={`${facultySearchUrl + query}`}>
              find "{query}" within Faculty
            </a>
          </div>
          <div>
            <a href={`${researchSearchUrl + query}`}>
              find "{query}" within Research
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

QuickSearchBar.propTypes = propTypes;
export default QuickSearchBar;

const searchIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-search"
  >
    <circle cx="11" cy="11" r="8"></circle>
    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
  </svg>
);

const closeIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 20 20"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-x"
  >
    <line x1="20" y1="0" x2="0" y2="20"></line>
    <line x1="0" y1="0" x2="20" y2="20"></line>
  </svg>
);
