export const globalHeaderScrollTop = (globalHeader) => {
  // Test for global header, prevents console errors on scroll in Storybook when the global header is not present
  if (globalHeader) {
    let prevScrollpos = window.pageYOffset;

    const addRemoveScrollClass = () => {
      if (document.body.classList.contains("is-scrolling")) {
        return;
      }

      let currentScrollPos = window.pageYOffset;
      if (prevScrollpos > currentScrollPos) {
        document
          .getElementsByClassName("global-header")[0]
          .classList.remove("scroll-top");
        if (document.getElementsByClassName("secondary-navigation__mount")[0]) {
          document
            .getElementsByClassName("secondary-navigation__mount")[0]
            .classList.remove("scroll-top");
        }

        if (document.getElementsByClassName("jump-navigation__mount")[0]) {
          document
            .getElementsByClassName("jump-navigation__mount")[0]
            .classList.remove("scroll-top");
        }

        if (document.getElementsByClassName("page-header__mini--exec-ed")[0]) {
          document
            .getElementsByClassName("page-header__mini--exec-ed")[0]
            .classList.remove("scroll-top");
        }
      } else {
        document
          .getElementsByClassName("global-header")[0]
          .classList.add("scroll-top");

        if (document.getElementsByClassName("secondary-navigation__mount")[0]) {
          document
            .getElementsByClassName("secondary-navigation__mount")[0]
            .classList.add("scroll-top");
        }

        if (document.getElementsByClassName("jump-navigation__mount")[0]) {
          document
            .getElementsByClassName("jump-navigation__mount")[0]
            .classList.add("scroll-top");
        }

        if (document.getElementsByClassName("page-header__mini--exec-ed")[0]) {
          document
            .getElementsByClassName("page-header__mini--exec-ed")[0]
            .classList.add("scroll-top");
        }

        let currentMenu = document.querySelectorAll(
          ".js-desktop-header-item.is-open",
        );

        //Remove is-open class if exist to hide primary nav
        if (currentMenu.length) {
          currentMenu[0].classList.remove("is-open");
        }
      }
      prevScrollpos = currentScrollPos;
    };

    window.addEventListener("scroll", addRemoveScrollClass);
  }
};
